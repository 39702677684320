export const REACT_APP_NAME =
  "PokeGrid | Pokemon Grid Game for Trivia and Sudoku Fans";
export const REACT_APP_DESC =
  "Play the popular new Pokemon game - PokeGrid! Complete your grid using Pokemon from any generation and earn rarity points for unique picks.";
export const REACT_APP_URL = "https://pokegrid.net/";
export const REACT_APP_IMG_URL = "https://pokegrid.net/img/pokegrid-social.png";

export function addProductJsonLd() {
  return {
    __html: `{
      "@context" : "https://schema.org",
      "@type" : "WebSite",
      "name" : "PokeGrid",
      "url" : "https://pokegrid.net/"
    }
  `,
  };
}
