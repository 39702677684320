import Head from "next/head";
import { REACT_APP_NAME } from "../lib/utils/metatags";
import PageIndex from "../components/pages/PageIndex";

export default function SSG() {
  return (
    <>
      <Head>
        <title>{REACT_APP_NAME}</title>
        <meta name="title" content={REACT_APP_NAME} />
      </Head>
      <PageIndex />
    </>
  );
}
